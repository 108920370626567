














import { Component, Prop, Vue } from "vue-property-decorator";
import { Facility } from '@/models';

@Component({
  components: {
    FacilityItem: () => import('./FacilitiesItem')
  }
})
export default class ModGroup extends Vue {  
  @Prop() facilities!: Array<Facility>  

  showEditForm(item: Facility) {
    this.$emit('showEditForm', item)
  }
}
